import type { AuthProviderProps } from "react-oidc-context";
import { useAuth } from "react-oidc-context";
import { Secured } from "./components/Secured";
import { UnSecured } from "./components/UnSecured";
import { gql } from 'graphql-request'
import { useCallback, useEffect, useState } from "react";
import { graphql } from "./request";


export const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

const getTokenQuery = gql`
  query getToken($token: String!) {
    refreshToken(token: $token)
  }
`

interface ResponseToken {
  refreshToken: String;
}

export const useUser = () => {
  const auth = useAuth();
  const logIn = useCallback(() => void auth.signinRedirect(), [auth]);
  const logOut = useCallback(() => void auth.removeUser(), [auth]);

  const [token, setToken] = useState<String>();
  useEffect(() => {
    const access_token = auth.user?.access_token;
    if (auth.user?.access_token) {
      graphql.request<ResponseToken>(getTokenQuery, {
        token: access_token
      }).then((response) => {
        setToken(response.refreshToken)
      })
    }
  }, [auth.user])

  return {
    user: token ? auth.user?.profile : undefined,
    token,
    logIn,
    logOut,
  }
}

export const AuthValve = () => {
  const auth = useAuth();
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in ...</div>;
    case "signoutRedirect":
      return <div>Signing you out ...</div>;
  }

  if (auth.isLoading) {
    return <div style={{textAlign: "center"}}>Cargando...</div>;
  }

  if (auth.error) {
    return <div>Oops... Auth error {auth.error.message} </div>;
  }

  return auth.isAuthenticated ? <Secured /> : <UnSecured />;
}

