import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useAirflows } from "../request";
import { gql } from "graphql-request";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import SignUpForm from "./sign-up-form";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import OfficeDetails from "./office-details";
import { useEffect, useState } from "react";
import DeleteUserModal from "./delete-user-modal";
import SearchBar, { UsersFilters } from "./search-bar";

export interface User {
  Apellido1: String;
  Apellido2: String;
  Nombre: String;
  Email: String;
  NIF: String;
  Username: String;
  Rol: String;
  Oficina: number;
  id: number;
}

const queryClient = new QueryClient();

const getUsers = gql`
  query getUsers {
    PortalSuscripcion_Usuarios_registradosList(orderBy: { attribute: id, direction: ASC }) {
      Apellido1
      Apellido2
      Nombre
      Email
      NIF
      Username
      Rol
      Oficina
      id
    }
  }
`;

interface ResponseUsers {
  PortalSuscripcion_Usuarios_registradosList: {
    Apellido1: String;
    Apellido2: String;
    Nombre: String;
    Email: String;
    NIF: String;
    Username: String;
    Rol: String;
    Oficina: number;
    id: number;
  }[];
}

const getOffices = gql`
  query getOffices {
    PortalSuscripcion_OficinaList(orderBy: { attribute: id, direction: ASC }) {
      codOficina
      id
    }
  }
`;

interface ResponseOffices {
  PortalSuscripcion_OficinaList: {
    codOficina: String;
    id: number;
  }[];
}

const itemsPerPage: number = 5;

export const Secured = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Content />
    </QueryClientProvider>
  );
};

const Content = () => {
  let [currentPage, setCurrentPage] = useState(0);

  const userSelectedInitialState = {
    Apellido1: "",
    Apellido2: "",
    Nombre: "",
    Email: "",
    NIF: "",
    Username: "",
    Rol: "",
    Oficina: -1,
    id: -1,
  }

  const airflows = useAirflows();
  const { data: dataUsers, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      if (airflows) {
        const { PortalSuscripcion_Usuarios_registradosList } = await airflows<ResponseUsers>(getUsers);
        return PortalSuscripcion_Usuarios_registradosList;
      }
    },
    enabled: !!airflows,
  });

  const { data: dataOffices } = useQuery({
    queryKey: ["offices"],
    queryFn: async () => {
      if (airflows) {
        const { PortalSuscripcion_OficinaList } = await airflows<ResponseOffices>(getOffices);
        return PortalSuscripcion_OficinaList;
      }
    },
    enabled: !!airflows,
  });

  const [officeId, setOfficeId] = useState(-1);
  const [userSelected, setUserSelected] = useState<User>(userSelectedInitialState);
  const [usersList, setUserList] = useState<User[] | undefined>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const { isOpen: isOpenSignUp, onOpen: onOpenSignUp, onClose: onCloseSignUp } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const { isOpen: isOpenOfficeCode, onOpen: onOpenOfficeCode, onClose: onCloseOfficeCode } = useDisclosure();

  const showOfficeCode = (idOffice: number) => {
    return (<Text>{dataOffices?.find((office) => office.id === idOffice)?.codOficina}</Text>)
  }

  const getFilters = (filters: UsersFilters) => {    
    setCurrentPage(0);
    if(filters.nif !== "" || filters.email !== ""){
      const itemsFiltered = dataUsers?.filter((user) => user.NIF.includes(filters.nif) && user.Email.includes(filters.email));
      setUserList(itemsFiltered);
    } else {     
      setUserList(dataUsers);
    }
  }

  const resetUserListView = () => {
    refetch();
    setCurrentPage(0);
  }

  useEffect(() => {
    setUserList(dataUsers);
  }, [dataUsers]);

  return (
    <Box textAlign="center" fontSize="md">
      <VStack spacing={8} pt={8}>
        {isLoading && (
          <CircularProgress isIndeterminate value={30} size="120px" />
        )}
        {isSuccess && (
          <Box minW={"70vw"}>
          <Flex justifyContent="space-between" alignItems="flex-end" mb={8}>
            <SearchBar getFilters={getFilters}/>
            <Button size="sm" onClick={() => {setUserSelected(userSelectedInitialState); setIsEditMode(false); onOpenSignUp()}}>Añadir</Button>
          </Flex>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>DNI / NIF</Th>
                    <Th>Nombre y Apellidos</Th>
                    <Th>Email</Th>
                    <Th>Rol</Th>
                    <Th>Oficina</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersList &&
                    usersList.slice(currentPage * itemsPerPage, itemsPerPage * (currentPage + 1)).map((el) => (
                      <Tr key={el.id}>
                        <Td>{el.NIF}</Td>
                        <Td>{el.Nombre} {el.Apellido1} {el.Apellido2}</Td>
                        <Td>{el.Email}</Td>
                        <Td>{el.Rol}</Td>
                        {el.Oficina ?
                          <Td>
                            <Button size='xs' fontSize='sm' variant='outline'
                              onClick={() => {setOfficeId(el.Oficina); onOpenOfficeCode()}}>
                              {showOfficeCode(el.Oficina)}
                            </Button>
                          </Td>
                        :<Td></Td>}
                        <Td>
                          <EditIcon mr={2} onClick={() => {setUserSelected(el); setIsEditMode(true); onOpenSignUp()}}/>
                          <DeleteIcon onClick={() => {setUserSelected(el); onOpenDelete()}}/>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </VStack>
      {isOpenSignUp && <SignUpForm userSelected={userSelected} isEditMode={isEditMode} isOpenSignUp={isOpenSignUp} onCloseSignUp={onCloseSignUp} resetUserListView={resetUserListView}/>}
      {isOpenDelete && <DeleteUserModal userSelected={userSelected} isOpenDelete={isOpenDelete} onCloseDelete={onCloseDelete} resetUserListView={resetUserListView}/>}
      {isOpenOfficeCode && <OfficeDetails officeId={officeId} isOpenOfficeCode={isOpenOfficeCode} onCloseOfficeCode={onCloseOfficeCode} />}
      
      <Flex justifyContent='center' gap={2} my={4}>
        {currentPage !== 0 && <Button size='sm' onClick={() => setCurrentPage(currentPage -= 1)}>Anterior</Button>}
        {pagination(usersList?.length as number, itemsPerPage, currentPage) && <Button size='sm' onClick={() => setCurrentPage(currentPage += 1)}>Siguiente</Button>}
      </Flex>
    </Box>
  );
};

const pagination = (totalItems: number, pagNumber: number, currentPage: number) => {
  return Math.ceil(totalItems / pagNumber) !== currentPage + 1;
}