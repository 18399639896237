import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, ModalFooter, Button, useToast } from "@chakra-ui/react";
import { User } from "./Secured";
import { useAirflows } from "../request";
import { useUser } from "../auth";
import { gql } from "graphql-request";

interface IDeleteUserModalProps{
    userSelected?: User;
    isOpenDelete: boolean;
    onCloseDelete: () => void;
    resetUserListView: () => void;
}

interface DeleteUserResponse {
  PortalSuscripcion_Usuarios_registradosDelete: {
    Nombre: String;
  }
}

const DeleteUserModal = ({
    userSelected,
    isOpenDelete,
    onCloseDelete,
    resetUserListView,
}: IDeleteUserModalProps) => {
  const airflows = useAirflows();
  const { token } = useUser();
  const toast = useToast();

  const deleteUser = async () => {
    try {
      
      const variables = {
        id: userSelected?.id,
        authorization: token
      };

      const mutation = gql`
        mutation DeteleUser(
          $id: Int
        ) {
          PortalSuscripcion_Usuarios_registradosDelete(where: {id:{ EQ: $id}}) {
            Nombre
          }
        }
      `;

      if(airflows){
        const { PortalSuscripcion_Usuarios_registradosDelete } = await airflows<DeleteUserResponse>(mutation, variables)
        if(PortalSuscripcion_Usuarios_registradosDelete){
          resetUserListView();
          onCloseDelete();
          toast({
            title: `Registro de Usuario`,
            description: `El usuario ${userSelected?.Username} ha sido elimanado correctamente de la aplicación`,
            status: "success",
            duration: 3000,
            isClosable: true
          })
        }
        return PortalSuscripcion_Usuarios_registradosDelete;
      }
      
    } catch (error) {
      toast({
        title: `Eliminación de Usuario`,
        description: `Ha ocurrido un error al eliminar al usuario ${userSelected?.Username} de la aplicación`,
        status: "error",
        duration: 3000,
        isClosable: true
      })
      throw new Error("Failed to delete user");
    }
  };

  return (
    <Modal isOpen={isOpenDelete} onClose={onCloseDelete} isCentered size={'lg'}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Eliminar Usuario {userSelected?.Username}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>¿Estás seguro de eliminar al usuario '{userSelected?.Username}'?</Text>
      </ModalBody>
      <ModalFooter>
      <Button variant="ghost" onClick={onCloseDelete}>
          Cancelar
        </Button>
        <Button colorScheme="blue" mr={3} onClick={deleteUser}>
          Eliminar
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  )
}

export default DeleteUserModal