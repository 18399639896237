import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useAirflows } from "../request";
import { useUser } from "../auth";
import { useQuery } from "@tanstack/react-query";

interface IOfficeDetailsProps {
  officeId: number;
  isOpenOfficeCode: boolean;
  onCloseOfficeCode: () => void;
}

interface ResponseOfficeDetails {
  PortalSuscripcion_OficinaList: {
    codOficina: String;
    descOficina: String;
    localizacion: String;
    id: number;
  }[];
}

const getOfficeDetails = gql`
query getOfficeDetails($id: Int) {
  PortalSuscripcion_OficinaList(where: { id: { EQ: $id } }) {
    id
    codOficina
    descOficina
    localizacion
  }
}
`;

interface ResponseUsers {
  PortalSuscripcion_Usuarios_registradosList: {
    Apellido1: String;
    Apellido2: String;
    Nombre: String;
    Email: String;
    NIF: String;
    Username: String;
    Rol: String;
    id: number;
  }[];
}

const getUsersByOffice = gql`
  query getUsersByOffice($id: Int) {
    PortalSuscripcion_Usuarios_registradosList(where: {Oficina: {EQ: $id}} orderBy: { attribute: id, direction: ASC }){
      Apellido1
      Apellido2
      Nombre
      Email
      NIF
      Rol
      id
    }
  }
`

const OfficeDetails = ({
  officeId,
  isOpenOfficeCode,
  onCloseOfficeCode,
}: IOfficeDetailsProps) => {
  const airflows = useAirflows();
  const { token } = useUser();

  const variables = {
    id: officeId,
    authorization: token,
  };

  const { data: dataOffice, isLoading, isSuccess } = useQuery({
    queryKey: ["office"],
    queryFn: async () => {
      if (airflows) {
        const { PortalSuscripcion_OficinaList } = await airflows<ResponseOfficeDetails>(getOfficeDetails, variables);
        return PortalSuscripcion_OficinaList;
      }
    },
    enabled: !!airflows,
  });

  const { data: dataUsersByOffice } = useQuery({
    queryKey: ["usersbyoffice"],
    queryFn: async () => {
      if (airflows) {
        const { PortalSuscripcion_Usuarios_registradosList } = await airflows<ResponseUsers>(getUsersByOffice, variables);
        return PortalSuscripcion_Usuarios_registradosList;
      }
    },
    enabled: !!airflows,
  });

  return (
    <Modal
      isOpen={isOpenOfficeCode}
      onClose={onCloseOfficeCode}
      isCentered
      size={"5xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {isLoading && <Spinner />}
        { isSuccess && dataOffice && (
          <>
            <ModalHeader>Datos de la Oficina {dataOffice[0].codOficina}</ModalHeader>

            <ModalBody>
              <Flex gap={2} fontSize="lg">
                <Text fontWeight="bold">Oficina:</Text>
                <Text>{dataOffice[0].descOficina}</Text>
              </Flex>
              <Divider my={4}/>
              <Text fontSize="lg" fontWeight="bold">Usuarios</Text>
              <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>DNI / NIF</Th>
                    <Th>Nombre y Apellidos</Th>
                    <Th>Email</Th>
                    <Th>Rol</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataUsersByOffice &&
                    dataUsersByOffice.map((el) => (
                      <Tr key={el.id}>
                        <Td>{el.NIF}</Td>
                        <Td>{el.Nombre} {el.Apellido1} {el.Apellido2}</Td>
                        <Td>{el.Email}</Td>
                        <Td>{el.Rol}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default OfficeDetails;
