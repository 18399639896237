import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";

export interface UsersFilters {
  nif: string;
  email: string;
}

interface ISearchBarProps {
  getFilters: (filters: UsersFilters) => void;
}

const SearchBar = ({ getFilters }: ISearchBarProps) => {
  const initialStateFilters = {
    nif: "",
    email: "",
  };

  const [filters, setFilters] = useState<UsersFilters>(initialStateFilters);

  const onChangeValuesInput =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setFilters((old) => ({
        ...old,
        [key]: e.target.value,
      }));
    };
  
  return (
    <Flex alignItems="flex-end" gap={4} fontSize="sm">
      <Box>
        <Text align={"left"} ml={2}>
          DNI / NIF
        </Text>
        <Input size="sm" onChange={onChangeValuesInput("nif")} value={filters.nif}/>
      </Box>
      <Box>
        <Text align={"left"} ml={2}>
          Email
        </Text>
        <Input size="sm" onChange={onChangeValuesInput("email")} value={filters.email}/>
      </Box>
      <Button size="sm" onClick={() => getFilters(filters)}>
        Buscar
      </Button>
      <Button size="sm" variant="outline" onClick={() => {setFilters(initialStateFilters); getFilters(initialStateFilters)}}>
        Reiniciar
      </Button>
    </Flex>
  );
};

export default SearchBar;
