import {
  Box,
  Text,
  VStack
} from "@chakra-ui/react";

export const UnSecured = () => {
  return <Box display="flex" h={'calc(99vh - 100px)'} justifyContent="center" alignItems="center" fontSize="xl">
    <VStack spacing={8}>
      <Text fontSize="5xl">
        Panel de Gestión
      </Text>
    </VStack>
  </Box>;
};

