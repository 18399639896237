import { GraphQLClient, Variables } from "graphql-request";
import { GraphQLClientRequestHeaders, RequestDocument } from "graphql-request/build/esm/types";
import { useUser } from "./auth";

const endpoint = 'https://minsait-vcx-dev.flows.ninja/graphql';

export const graphql = new GraphQLClient(endpoint);

export const useAirflows = () => {
  const { token } = useUser();
  if (!token) return;
  const client = new GraphQLClient(endpoint);
  const airflows = <K>(document: RequestDocument, variables?: Variables, headers?: GraphQLClientRequestHeaders) => client.request<K>(document, {
    ...variables,
    authorization: token
  }, headers)
  return airflows;
}
