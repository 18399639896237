import {
  ChakraProvider,
  Grid,
  theme,
} from "@chakra-ui/react"
import { Nav } from "./components/Nav"
import { AuthProvider } from "react-oidc-context";
import { AuthValve, oidcConfig } from "./auth"


export const App = () => (
  <ChakraProvider theme={theme}>
    <AuthProvider {...oidcConfig}>
      <Grid p={3}>
        <Nav />
        <AuthValve />
      </Grid>
    </AuthProvider>
  </ChakraProvider>
)

