import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { useAirflows } from "../request";
import { useUser } from "../auth";
import { gql } from "graphql-request";
import { User } from "./Secured";
import { useQuery } from "@tanstack/react-query";

interface ISignUpFormProps {
  userSelected?: User;
  isOpenSignUp: boolean;
  isEditMode: boolean;
  onCloseSignUp: () => void;
  resetUserListView:() => void;
}

interface UserSignUpProfile {
  Username: string;
  Email: string;
  Rol: string;
  NIF: string;
  Nombre: string;
  Apellido1: string;
  Apellido2: string;
  Oficina: number | null;
}

interface CreateUserResponse {
  PortalSuscripcion_Usuarios_registradosCreate: {
    Username: string;
    Email: string;
  };
}

interface UpdateUserResponse {
  PortalSuscripcion_Usuarios_registradosUpdate: {
    Nombre: string;
    Apellido1: string;
  };
}

const getOffices = gql`
  query getOffices {
    PortalSuscripcion_OficinaList(orderBy: { attribute: id, direction: ASC }) {
      id
      codOficina
      descOficina
    }
  }
`;

interface ResponseOffices {
  PortalSuscripcion_OficinaList: {
    id: number;
    codOficina: String;
    descOficina: String;
  }[];
}

const SignUpForm = ({
  userSelected,
  isOpenSignUp,
  isEditMode,
  onCloseSignUp,
  resetUserListView,
}: ISignUpFormProps) => {
  const airflows = useAirflows();
  const { token } = useUser();
  const toast = useToast();

  const profileInitialState = {
    Username: userSelected ? userSelected.Username as string : "",
    Email: userSelected ? userSelected.Email as string : "",
    Rol: userSelected ? userSelected.Rol as string : "",
    NIF: userSelected ? userSelected.NIF as string : "",
    Nombre: userSelected ? userSelected.Nombre as string : "",
    Apellido1: userSelected ? userSelected.Apellido1 as string : "",
    Apellido2: userSelected ? userSelected.Apellido2 as string : "",
    Oficina: userSelected ? userSelected.Oficina : null
  };
  
  const [profileToSend, setProfileToSend] = useState<UserSignUpProfile>(profileInitialState);

  const rolOptions: string[] = [
    "Gestor_Global",
    "Gestor_Oficina",
    "Asegurado",
    "Tomador",
  ];

  const { data: dataOffices } = useQuery({
    queryKey: ["offices"],
    queryFn: async () => {
      if (airflows) {
        const { PortalSuscripcion_OficinaList } = await airflows<ResponseOffices>(getOffices);
        return PortalSuscripcion_OficinaList;
      }
    },
    enabled: !!airflows,
  });
  
  const onChangeValuesInput =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setProfileToSend((old) => ({
        ...old,
        [key]: e.target.value,
      }));
    };

  const onChangeValuesSelect =
    (key: string) => (e: ChangeEvent<HTMLSelectElement>) => {
      setProfileToSend((old) => ({
        ...old,
        [key]: e.target.value,
      }));
    };
 
  const createUser = async () => {
    try {
      if (!token) return;
      if(profileToSend.Rol !== "Gestor_Oficina"){
        profileToSend.Oficina = null
      } else {
        profileToSend.Oficina = Number(profileToSend.Oficina)
      }
      const variables = {
        name: profileToSend.Nombre,
        username: profileToSend.Username,
        email: profileToSend.Email,
        firstSurname: profileToSend.Apellido1,
        secondSurname: profileToSend.Apellido2,
        rol: profileToSend.Rol,
        nif: profileToSend.NIF,
        oficina: profileToSend.Oficina,
        authorization: token,
      };

      const mutation = gql`
        mutation CreateUser(
          $name: String!
          $username: String!
          $email: String!
          $firstSurname: String!
          $secondSurname: String
          $rol: PortalSuscripcion_RolesEnumType!
          $nif: String!
          $oficina: Int
        ) {
          PortalSuscripcion_Usuarios_registradosCreate(
            entity: {
              Nombre: $name
              Username: $username
              Email: $email
              Apellido1: $firstSurname
              Apellido2: $secondSurname
              Rol: $rol
              NIF: $nif
              Oficina: $oficina
            }
          ) {
            Username
            Email
          }
        }
      `;
        
     if (airflows) {
        const { PortalSuscripcion_Usuarios_registradosCreate } = await airflows<CreateUserResponse>(mutation, variables);
        if(PortalSuscripcion_Usuarios_registradosCreate){
          resetUserListView();
          onCloseSignUp();
          toast({
            title: `Registro de Usuario`,
            description: `El usuario ${profileToSend.Username} ha sido registrado correctamente en la aplicación`,
            status: "success",
            duration: 3000,
            isClosable: true
          })
          setProfileToSend(profileInitialState);
        }
        return PortalSuscripcion_Usuarios_registradosCreate;
      }
      
    } catch (error) {
      toast({
        title: `Registro de Usuario`,
        description: `Ha ocurrido un error al registrar al usuario ${profileToSend.Username} en la aplicación`,
        status: "error",
        duration: 3000,
        isClosable: true
      })
      throw new Error("Failed to create user");
    }
  };

  const updateUser = async () => {
    try {
      if (!token) return;
      if(profileToSend.Rol !== "Gestor_Oficina"){
        profileToSend.Oficina = null
      } else {
        profileToSend.Oficina = Number(profileToSend.Oficina)
      }
      const variables = {
        name: profileToSend.Nombre,
        username: profileToSend.Username,
        email: profileToSend.Email,
        firstSurname: profileToSend.Apellido1,
        secondSurname: profileToSend.Apellido2,
        rol: profileToSend.Rol,
        nif: profileToSend.NIF,
        oficina: profileToSend.Oficina,
        authorization: token,
      };

      const mutation = gql`
        mutation UpdateUser(
          $name: String!
          $username: String!
          $email: String!
          $firstSurname: String!
          $secondSurname: String
          $rol: PortalSuscripcion_RolesEnumType!
          $nif: String!
          $oficina: Int
        ) {
          PortalSuscripcion_Usuarios_registradosUpdate(
            entity: {
              Nombre: $name
              Username: $username
              Email: $email
              Apellido1: $firstSurname
              Apellido2: $secondSurname
              Rol: $rol
              NIF: $nif
              Oficina: $oficina
            }, where : {Email:{ EQ: $email}}
          ) {
            Nombre
            Apellido1
          }
        }
      `;

      if(airflows) {
        const { PortalSuscripcion_Usuarios_registradosUpdate } = await airflows<UpdateUserResponse>(mutation, variables);
        if(PortalSuscripcion_Usuarios_registradosUpdate){
          resetUserListView();
          onCloseSignUp();
          toast({
            title: `Actualización de Usuario`,
            description: `El usuario ${profileToSend.Username} ha sido actualizado correctamente en la aplicación`,
            status: "success",
            duration: 3000,
            isClosable: true
          })
          setProfileToSend(profileInitialState);
        }
        return PortalSuscripcion_Usuarios_registradosUpdate;
      }
    } catch (error) {
      toast({
        title: `Actualización de Usuario`,
        description: `Ha ocurrido un error al actualizar el usuario ${profileToSend.Username} de la aplicación`,
        status: "error",
        duration: 3000,
        isClosable: true
      })
      throw new Error("Failed to update user");
    }

  }

  return (
    <Modal isOpen={isOpenSignUp} onClose={onCloseSignUp} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isEditMode ? "Actualizar" : "Registrar"} Usuario
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg">Datos de Registro</Text>
          <FormControl
            isRequired
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel>Usuario</FormLabel>
            <Input
              placeholder="Nombre de usuario"
              width="xs"
              defaultValue={userSelected ? (userSelected.Username as string) : ""}
              onChange={onChangeValuesInput("Username")}
            />
          </FormControl>
          <FormControl
            isRequired
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Email"
              width="xs"
              defaultValue={userSelected ? (userSelected.Email as string) : ""}
              onChange={onChangeValuesInput("Email")}
            />
          </FormControl>
          <FormControl
            isRequired
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel>Rol</FormLabel>
            <Select
              placeholder="Seleccione una opción"
              width="xs"
              defaultValue={userSelected ? (userSelected.Rol as string) : ""}
              onChange={onChangeValuesSelect("Rol")}
            >
              {rolOptions.map((rol, index) => (
                <option key={index}>{rol}</option>
              ))}
            </Select>
          </FormControl>
          {(profileToSend.Rol === "Gestor_Oficina" || userSelected?.Rol === "Gestor_Oficina") && (
          <FormControl
            isRequired
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel>Oficina</FormLabel>
            <Select
              placeholder="Seleccione una opción"
              width="xs"
              defaultValue={userSelected ? (userSelected.Oficina) : ""}
              onChange={onChangeValuesSelect("Oficina")}
            >
              {dataOffices?.map((office, index) => (
                <option key={index} value={office.id}>{office.descOficina} - {office.codOficina}</option>
              ))}
            </Select>
          </FormControl>)}
          <Divider my={4} />
          <Text fontSize="lg">Datos Personales</Text>
            <FormControl
              isRequired
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={2}
            >
              <FormLabel>DNI / NIF</FormLabel>
              <Input
                placeholder="DNI / NIF"
                width="xs"
                defaultValue={userSelected ? (userSelected.NIF as string) : ""}
                onChange={onChangeValuesInput("NIF")}
              />
            </FormControl>
            <FormControl
              isRequired
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={2}
            >
              <FormLabel>Nombre</FormLabel>
              <Input
                placeholder="Nombre"
                width="xs"
                defaultValue={userSelected ? (userSelected.Nombre as string) : ""}
                onChange={onChangeValuesInput("Nombre")}
              />
            </FormControl>
            <FormControl
              isRequired
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={2}
            >
              <FormLabel>Apellido 1</FormLabel>
              <Input
                placeholder="Apellido 1"
                width="xs"
                defaultValue={userSelected ? (userSelected.Apellido1 as string) : ""}
                onChange={onChangeValuesInput("Apellido1")}
              />
            </FormControl>
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={2}
            >
              <FormLabel>Apellido 2</FormLabel>
              <Input
                placeholder="Apellido 2"
                width="xs"
                defaultValue={userSelected ? (userSelected.Apellido2 as string) : ""}
                onChange={onChangeValuesInput("Apellido2")}
              />
            </FormControl>
          <ModalFooter gap={2} p={0} my={4}>
            <Button variant="ghost" onClick={onCloseSignUp}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={isEditMode ? updateUser : createUser}
            >
              {isEditMode ? "Actualizar" : "Registrar"}
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignUpForm;
